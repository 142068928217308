
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted,defineComponent } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { ElMessage } from "element-plus";
import { client } from "@/client";
import {
  loadFile,
} from "@/utils/tools";
import { pinyin } from "pinyin-pro";
export default defineComponent({
  name: "frag-add",
  components: {
    Editor, Toolbar
  },
  setup() {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('<p>hello</p>')

    // 模拟 ajax 异步获取内容
    onMounted(() => {
        setTimeout(() => {
            valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
        }, 1500)
    })
    const toolbarConfig = {}
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF:{
        uploadImage:{
          // 自定义上传
          async customUpload(file: File, insertFn: (arg0: string, arg1: string, arg2: string) => void) {  // TS 语法
            let fileData = await loadFile(file);
            let ret = await client.callApi("pub/UploadImage",{file: fileData,filename: file.name});
            if (ret.isSucc) {
              let path = client.options.server + ret.res.path;
              insertFn(path,path, path)
            } else {
              ElMessage("上传文件必须小于1M");
            }
          // async customUpload(file, insertFn) {                   // JS 语法
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
          }
        }
      }
    }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor: any) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  data: () => {
    return {
      params: {
        //接口入参
        id: 0,
        title: "",
        mark: "",
        createdAt: new Date(),
        content: "禅悦",
      },
      dialogVisible: false,
      disabled: false,
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  async created() {
    let id = this.$route.params.id as string;
    
    this.params.id = parseInt(id);
    await this.detail();
  },
  methods: {
    setContent(article: string) {
      this.params.content = article;
    },
    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("frag/Detail",{id:this.params.id});
        if (res.isSucc) {
          this.params = {
            ...res.res
          };
        }
      } catch (error) {
        console.error(error);
      }
    },

    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },

    //新增
    async update() {
      try {
        let res = await client.callApi("frag/Update",{
          ...this.params
        })
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string|number) {
      let refs:any = this.$refs[formName];
      refs.validate((valid: any) => {
        if (valid) {
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
